
import { computed, onMounted, reactive, toRefs } from 'vue'
import { Options, setup, Vue } from 'vue-class-component'
import orderService from '@/services/order/order.service'

import Swal from 'sweetalert2/dist/sweetalert2.min.js'
import OrderResponseData from '@/services/order/responses/OrderResponseData'
import Order from '@/services/order/Order'
import DateUtils from '@/utils/DateUtils'

import OrderDetailDrawer from '@/components/orders/drawer/OrderDetailDrawer.vue'
import { DrawerComponent } from '@/assets/ts/components/_DrawerComponent'
import { MenuComponent, ScrollComponent, ScrollTopComponent, StickyComponent, ToggleComponent } from '@/assets/ts/components'
import { Nullable } from '@/core/utils/CustomTypes'

interface ActiveOrderStateInfo {
  searchString: string,
  orderList: Order[],
  selectedOrder: Nullable<Order>
}

@Options({
  name: 'active-orders',
  components: {
    OrderDetailDrawer
  }
})
export default class ActiveOrdersComponent extends Vue {
  context = setup(() => {
    const activeOrdersState = reactive<ActiveOrderStateInfo>({
      searchString: '',
      orderList: [],
      selectedOrder: null
    })

    const filteredOrderList = computed(() => {
      const text = activeOrdersState.searchString.normalize('NFD').replace(/[\u0300-\u036f]/g, '')

      return activeOrdersState.orderList
        .filter((order) => {
          return !activeOrdersState.searchString ||
            order.OrderId?.toString()?.toLowerCase()?.includes(text.toLowerCase()) ||
            order.OrderCheffId?.toLowerCase()?.includes(text.toLowerCase()) ||
            order.ClientName?.normalize('NFD').replace(/[\u0300-\u036f]/g, '')?.toLowerCase()?.includes(text.toLowerCase()) ||
            order.BranchName?.normalize('NFD').replace(/[\u0300-\u036f]/g, '')?.toLowerCase()?.includes(text.toLowerCase()) ||
            order.OrderStatus?.OrderStatusName?.toLowerCase()?.includes(text.toLowerCase()) ||
            order.OrderStatus?.OrderStatusShortName?.toLowerCase()?.includes(text.toLowerCase())
        })
    })

    const loadActiveOrders = () => {
      orderService.getActiveOrderList()
        .then((data: OrderResponseData) => {
          activeOrdersState.orderList = data.OrderList ?? []
        })
        .catch((error: Error) => {
          Swal.fire({
            text: error.message,
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Cerrar',
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger'
            }
          })
        })
    }

    onMounted(() => {
      loadActiveOrders()

      ToggleComponent.reinitialization()
      ScrollComponent.reinitialization()
      ScrollTopComponent.reinitialization()
      DrawerComponent.reinitialization()
      StickyComponent.reInitialization()
      MenuComponent.reinitialization()
    })

    const onOrderClick = (order: Order) => {
      activeOrdersState.selectedOrder = order
      const drawerElement = document.getElementById('kt_activities')
      if (!drawerElement) {
        return
      }

      const drawerInstance = DrawerComponent.getInstance(drawerElement)
      drawerInstance?.show?.()

      const scrollElement: HTMLElement | null = drawerElement.querySelector('[data-kt-scroll="true"]')
      if (!scrollElement) {
        return
      }

      const scrollInstance = ScrollComponent.getInstance(scrollElement)
      scrollInstance?.update?.()
    }

    return {
      ...toRefs(activeOrdersState),
      filteredOrderList,
      loadActiveOrders,
      onOrderClick,

      ...DateUtils.toLiteral()
    }
  })
}
